import logo from '../assets/images/TRONDHEIMDODGEBALLFIRSTLOGO.PNG';
import './HomePage.css';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";

function HomePage() {
  const navigate = useNavigate();
  return (
    <div className="App">
      <header className="App-header">
      <p>
          Welcome to Trondheim Dodgeball! This is a work in progress. Feedback is welcomed, visit the 
          <Link to="/membership" style={{marginLeft: "10px"}}>contact page.</Link>
        </p>
        <img src={logo} className="work-in-progress-logo"/>
      </header>
    </div>
  );
}

export default HomePage;