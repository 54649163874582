import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Optional for styling

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };
  
  return (
    <nav className="navbar">
      <button
        className="navbar-toggle"
        onClick={() => setMenuOpen(!menuOpen)}
      >
        ☰
      </button>      
      <ul className={`navbar-list ${menuOpen ? 'open' : ''}`}>
      <li><Link to="/" onClick={closeMenu}>Home</Link></li>
        <li><Link to="/weeklysessions" onClick={closeMenu}>Weekly sessions</Link></li>
        <li><Link to="/whatisdodgeball" onClick={closeMenu}>What is dodgeball?</Link></li>
        <li><Link to="/membership" onClick={closeMenu}>Membership</Link></li>
        <li><Link to="/contact" onClick={closeMenu}>How to contact us</Link></li>
      </ul>
    </nav>
  );
}

export default Navbar;