import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

// For production we want to use HTTPS, but for development HTTP is sufficient enough and will generate less warnings. 
// TODO: Verify that production works on HTTPS.
const isProduction = process.env.NODE_ENV === 'production';
const baseURL = isProduction ? 'https://' : 'http://';
console.log(`Running in ${isProduction ? 'production' : 'development'} mode: ${baseURL}`);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);