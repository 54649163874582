function WhatIsDodgeballPage() {
    return (
      <div className="App">
        <header className="App-header">
          <h1>What is DODGEBALL?</h1>
          <p>I can write for days about this... Updates will come!</p>
        </header>
      </div>
    );
  }

  export default WhatIsDodgeballPage;