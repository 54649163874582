import { Link } from "react-router-dom";

function MembershipPage() {
    return (
      <div className="App">
        <header className="App-header">
          <h1>Memberships</h1>
          <h2>Everyone can attend up to three sessions for free to try out dodgeball.</h2>
          <h3>After that we have a 6 month membership fee that grant full access to all our sessions. Trondheim Dodgeball sends this bill twice a year to its members.</h3>
          <p></p>
          <h4>Standard 6 month membership</h4>
          <p>At the cost of 500 NOK</p>
          <h4>Student 6 month membership (discounted price)</h4>
          <p>At the cost of 250 NOK</p>
          <p>For any questions, see the
          <Link to="/contact" style={{marginLeft: "8px", marginRight: "8px" }}>contact</Link>
            page link for contact information.</p>
        </header>
      </div>
    );
  }

  export default MembershipPage;