import { Link } from "react-router-dom";

function WeeklySessionsPage() {
    return (
      <div className="App">
        <header className="App-header">
          <h1>Weekly Sessions</h1>

          <div>
          <h2>
            Trondheim spektrum E2 every Thursday at 19.00. Be sure to check our Spond for updated times, they are announced every week. 
            </h2>
            <h2>
            <a href="https://group.spond.com/BVWBE" target="_blank" rel="noopener noreferrer">
              Click me:
              <img src="spond.png" style={{marginLeft: "10px", display: "inline", height: "60px", verticalAlign: "middle"}}/>
            </a>
          </h2>
          </div>
          <p>We have weekly sessions open for eveyone who wants to parcipitate. 
            Here you can try dodgeball regardless of skillevel and how many times you have tried before. Come with some friends or family, everyone is welcome. 
            We have newcommers almost everyweek. Everyone can try for free up to three times, for membership details visit
            <Link to="/membership"> membership page.</Link>
            </p>
          <p>Our sessions usually consists of a good warmup, circular training, some warmup drills and then alot of dodgeball. </p>
        </header>
      </div>
    );
  }

  export default WeeklySessionsPage;