function ContactPage() {
    return (
      <div className="App">
        <header className="App-header">
          <h1>How to get in touch with us</h1>
          <h3>You can reach out and message us trough various channels</h3>
          <p>You can find us on instagram on the following account: 
          <a href="https://www.instagram.com/trondheimdodgeball/" target="_blank" rel="noopener noreferrer" style={{marginLeft: "10px"}}>
              Click me:
              <img src="instagram.png" style={{marginLeft: "10px", display: "inline", height: "60px", verticalAlign: "middle"}}/>
            </a>
          </p>
          <p>You can also follow norwegian dodgeball on instagram where the national team posts contents from various events. 
          <a href="https://www.instagram.com/norwegiandodgeball/" target="_blank" rel="noopener noreferrer" style={{marginLeft: "10px"}}>
              Click me:
              <img src="instagram.png" style={{marginLeft: "10px", display: "inline", height: "60px", verticalAlign: "middle"}}/>
            </a>
          </p>
          <p>We have a spond group where every training session is organized. Be sure to jump on there to get the latest info about the sessions the current week.
          <a href="https://group.spond.com/BVWBE" target="_blank" rel="noopener noreferrer" style={{marginLeft: "10px"}}>
              Click me:
              <img src="spond.png" style={{marginLeft: "10px", display: "inline", height: "60px", verticalAlign: "middle"}}/>
            </a>
            </p>
          <p></p>
          <p>Trondheim Dodgeball is currently run by Yngve and Knut Johan. You can contact them directly on 
            <a href="mailto:post@trondheimdodgeball.no" style={{marginLeft: "8px", marginRight: "8px" }}>post@trondheimdodgeball.no</a> if there is anything you are wondering about!</p>
        </header>
      </div>
    );
  }

  export default ContactPage;