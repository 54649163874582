import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Navbar from './Navbar/Navbar';
import MembershipPage from './Membership/MembershipPage';
import WhatIsDodgeballPage from './WhatIsDodgeball/WhatIsDodgeballPage';
import WeeklySessionsPage from './WeeklySessions/WeeklySessionsPage';
import ContactPage from './Contact/ContactPage';
import HomePage from './Home/HomePage';

function App() {
  return (
    <div>
      <Navbar /> {/* Navbar stays on top */}
      <div className="content"> {/* Content area below the navbar */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/membership" element={<MembershipPage />} />
          <Route path="/weeklysessions" element={<WeeklySessionsPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/whatisdodgeball" element={<WhatIsDodgeballPage />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
